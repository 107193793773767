import Head from 'next/head';
import Script from 'next/script';
import React from 'react';

import HomePage from '@components/HomePage';
import HomeLayout from '@components/common/@Layout/HomeLayout';

import useUser from 'hooks/v2/queries/useUser';
import useRouterPush from 'hooks/v2/useRouterPush';

function Home() {
  const { data: user } = useUser();
  const { router } = useRouterPush();
  const centerKind =  user?.center_set?.find((center) => center.id === Number(router.query.center_id ?? 0))?.kind;

  return (
    <>
      <Head>
        <title>
          패밀리케어 ERP
          {centerKind && (centerKind === 'day_night_care' ? ' | 홈' : ' | 메인')}
        </title>
        <Script
          type="text/javascript"
          src={`//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.NEXT_PUBLIC_KAKAO_MAP_API_KEY}&libraries=services`}
        />
      </Head>
      <HomeLayout content={<HomePage />} />
    </>
  );
}

export default Home;
