import dynamic from 'next/dynamic';
import {
  Box,
  BoxProps,
  Flex,
  VStack,
} from '@chakra-ui/react';

import useUser from 'hooks/v2/queries/useUser';
import useRouterPush from 'hooks/v2/useRouterPush';

const DailyVisitSchedule = dynamic(() => import('./_fragments/DailyVisitSchedule'));
const ExpirationDate = dynamic(() => import('./_fragments/ExpirationDate'));
const HomePageLayout = dynamic(() => import('./_fragments/HomePageLayout'));
const NoticeSection = dynamic(() => import('./_fragments/NoticeSection'));
const RecipientsAndFacilityStatus = dynamic(() => import('./_fragments/RecipientsAndFacilityStatus'));
const Home = dynamic(() => import('containers/Home'));

interface HomePageContentProps extends BoxProps {}

function HomePageContent({ ...basisProps }: HomePageContentProps) {
  const { router } = useRouterPush();
  const { data: user } = useUser({ suspense: true });
  const currentCenter = user?.center_set.find((center) => center.id === Number(router.query.center_id ?? 0));

  if (!currentCenter) {
    return null;
  }

  const homePage = currentCenter.kind === 'day_night_care'
    ? <Home />
    : (
      <Flex
        m="0 auto"
        w={'100%'}
        h={'100%'}
        overflow={'auto'}
        display={'flex'}
        justifyContent={'center'}
      >
        <Box {...basisProps} overflowY="auto">
          <HomePageLayout
            left={<DailyVisitSchedule />}
            right={
              <VStack spacing="20px" minW={1104}>
                <RecipientsAndFacilityStatus />
                <ExpirationDate />
                <NoticeSection />
              </VStack>
            }
          />
        </Box>
      </Flex>
    );

  return homePage;
}

export default HomePageContent;
